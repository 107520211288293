@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Inter', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --white: #fff;
  --primary-bg: #23af70;
  --body-bg: #f5f9ff;
  --link-light: var(--white);
  --link-dark: var(--link-dark);
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  background-color: var(--body-bg);
  overflow-x: hidden;
}
a, a:hover, a:focus, button, button:hover {
  text-decoration: none;
  outline: none;
  border: none;
}
input, textarea {
  border: none; 
  outline: none;
}
img {
  width: 100%;
  max-width: 100%;
}
h1 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
}
h2 {
  font-size: 28px;
  font-weight: 600;
  color: #4a4a4a;
}
p {
  font-size: 16px;
  font-weight: 500;
}




.loading-screen {
  position: relative;
  padding: 0;
  background-color: #4bedc2;
  width: 0%;
  height: 100%;
}

.load-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 10;
  pointer-events: none;
}










.razao-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  gap: 2rem;
}
.razao-item {
  text-align: center;
  padding: 32px 14px;
  background: linear-gradient(116.39deg,#f5f9ff -20.58%,#eff1f9);
  border: 2px solid #fff;
  border-radius: 8px;
}


.ecosistema h2 {
  font-size: 36px;
}


a.redirectButton {
  color: #fff;
  background-color: #f7155e;
  font-weight: 500;
  padding: 12px 24px;
  border-radius: 8px;
  transition: all .3s;
  display: inline-block;
}

a.redirectButton svg {
  transition: ease-in-out .3s margin;
}
a.redirectButton:hover svg {
  margin-left: 10px;
}



.bg-green h2,
.bg-green p,
.bg-green {
  color: #fff;
}
.navbar {
  margin: 2rem auto 0;
  max-width: 1120px;
  padding: 8px 24px;
  border-radius: 20px;
  font-weight: 500;
  color: var(--link-dark);
  transition: all .5s ease-in-out;
  z-index: 999;
  position: absolute;
  left: 0;
  right: 0;
  background-color: var(--white) !important;
  filter: drop-shadow(0 100px 80px rgba(0, 0, 0, .07)) drop-shadow(0 41.7776px 33.4221px rgba(0, 0, 0, .0503198)) drop-shadow(0 22.3363px 17.869px rgba(0, 0, 0, .0417275)) drop-shadow(0 12.5216px 10.0172px rgba(0, 0, 0, .035)) drop-shadow(0 6.6501px 5.32008px rgba(0, 0, 0, .0282725)) drop-shadow(0 2.76726px 2.21381px rgba(0, 0, 0, .0196802));
}
.navbar-dark .navbar-nav .active>.nav-link, 
.navbar-dark .navbar-nav .nav-link.active, 
.navbar-dark .navbar-nav .nav-link.show, 
.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .nav-link {
  color: var(--link-dark);
  padding: 12px 24px;
}

.link-parceiro {
  color: var(--white) !important;
  background-color: #f00;
  border-radius: 10px;
}

.navbar-dark .navbar-nav .nav-link:focus, 
.navbar-dark .navbar-nav .nav-link:hover {
  color: #12141f;
}

/* .home, .about, .contact, .blog {
  padding: 8rem 1rem 4rem;
} */

.dropdown-menu.show {
  display: none;
}
.nav-item.dropdown:hover .dropdown-menu.show {
  display: block !important;
}

.navigation {
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}


.info__ajuda h2 {
  margin-bottom: 2rem;
  color: var(--link-dark);
  display: block;
}
.info__ajuda h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--link-dark);
  gap: .5rem;
  display: flex;
  align-items: center;
}
.info__ajuda p {
  color: #626262;
  max-width: 350px
}

.contact__form button {
  font-size: 16px;
    font-weight: 500;
    max-width: 460px;
    width: 100%;
    vertical-align: middle;
    padding: 12px 24px;
    border-radius: 8px;
    transition: all .3s ease-in-out;
    color: var(--white);
    background: var(--primary-bg);
}

.contact__form label {
  font-size: 16px;
  font-weight: 400;
  display: block;
  margin-bottom: 6px;
  color: var(--link-dark);
}
.contact__form input, .contact__form textarea {
  font-size: 14px;
  font-weight: 400;
  max-width: 460px;
  width: 100%;
  padding: 8px 0 12px 8px;
  background: transparent;
  border-bottom: 1px solid #c1d6e9;
  transition: border .1s ease-in-out;
  color: #626262;
}
.contact__form textarea {
  font-size: 14px;
  font-weight: 400;
  padding: 8px 12px 12px 8px;
  background: transparent;
  border-bottom: 1px solid #c1d6e9;
  transition: border .1s ease-in-out;
  max-width: 460px;
  height: 120px;
  width: 100%;
  color: #626262;
}


.footer__bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer__bottom img {
  margin-bottom: 1rem;
}

.footer {
  background-color: var(--primary-bg);
  color: var(--white);
}
.footer__top {
  justify-content: space-between;
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    grid-template-areas:
        "acesso . empresa suporte redesSociais"
        "ondeEstamos ondeEstamos sac dadosDaEmpresa dadosDaEmpresa";
}
.footer__top p {
  margin-bottom: 12px;
  color: var(--white);
}
.footer__top .acesso {
  grid-area: acesso;
}
.footer__top .empresa {
  grid-area: empresa;
}
.footer__top .suporte {
  grid-area: suporte;
}
.footer__top .redesSociais {
  grid-area: redesSociais;
}
.footer__top .ondeEstamos {
  grid-area: ondeEstamos;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.footer__top .sac {
  grid-area: sac;
}
.footer__top .dados {
  grid-area: dadosDaEmpresa;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.footer__top a {
  font-size: 16px;
  font-weight: 400;
  display: block;
  margin-bottom: 6px;
  color: #f5f9ff;
}

/* .banner__sections {

} */
.banner__sections {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.banner__sections .container {
  position: relative;
  z-index: 1;
}
.banner__sections::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary-bg) !important;
  opacity: .7;
}
.banner__sections.bg-full::after {
  opacity: 1;
}


.banner__sections h1 {
  color: var(--white);
}
.banner__sections p {
  max-width: 600px;
  color: #f5f9ff;
}
.banner__sections {
  padding: 12rem 1rem 4rem;
}

.about {
  background-color: #e6e9f3;
}
.about__item svg {
  max-width: 48px;
  margin-right: 0.5rem;
}
.about__item p {
  max-width: 280px;
}

.about__bottom .cards {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 3rem;
}
.about__bottom .card {
  border: none;
  border-radius: 12px;
  text-align: center;
  display: block;
  background-color: #e6e9f3;
  padding: 22px 24px;
  max-width: 350px;
}
.about__bottom .card h4 {
  margin-top: 8px;
  margin-bottom: 10px;
}



.tab-pane__benefits {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  grid-gap: 2rem;
  margin-bottom: 1rem;
}

.tab-content>.active {
  display: flex;
}
.nav-pills {
  background-color: #e6e9f3;
  padding: 10px;
    border-radius: 15px;
}
.nav-pills .nav-link, .nav-pills > .nav-link {
  padding: 16px 24px;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: var(--primary-bg);
}



.container.position-relative {
  overflow: hidden;
}
.container.position-relative::after {
  content: "";
  position: absolute;
  left: -240px;
  width: 400px;
  height: 400px;
  border-radius: 100%;
  border: 60px solid rgba(98,98,98,.06);
  top: 25%;
  z-index: -1;
}
.container.position-relative::before {
  content: "";
  position: absolute;
  right: -150px;
  width: 300px;
  height: 300px;
  border-radius: 100%;
  border: 60px solid rgba(98,98,98,.06);
  top: 0;
  z-index: -1;
}



@media screen and (max-width: 992px) {
  
.navbar-mobile {
  height: 0 !important;
  overflow: hidden !important;
  transition: ease .5s;
}
.navbar-mobile.show {
  height: 100% !important;
}
  .contact .container div.align-items-center {
    gap: 5rem;
  }
  .footer__top {
    grid-template-areas:
        " redesSociais ."
        "acesso suporte "
        "sac empresa"
        "ondeEstamos ondeEstamos"
        "dadosDaEmpresa dadosDaEmpresa";
  }
  .footer__top .redesSociais {
    display: flex;
    gap: 15px;
  }
  .tab-content > .active {
    flex-direction: column;
  }
  .tab-pane__benefits, .processo__grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .has-bg::after {
    display: none;
  }
  .localizacao {
    width: 100% !important;
    text-align: center;
  }
  .grid__status {
    grid-template-columns: 2fr !important;
  }
  .logistica {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 660px) {
  .processo__grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .gridFeita {
    grid-template-columns: 1fr !important;
  }
  .grid__status {
    grid-template-columns: 1fr !important;
  }
}


.burocracia, .localizacao {
  width: 50%;
}
.processo__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.grid__item {
  border: 1px solid #cbd3d0;
  background: rgba(245,249,255,.7);
  border-radius: 10px;
}
.grid__item .number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  padding: 6px;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background: var(--primary-bg);
  color: #fff;
}
.grid__item h3 {
  margin-top: 18px;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
  color: #4a4a4a;
}
.grid__item p {
  color: #626262;
}



.gridFeita {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.feita__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.feita__item p {
  max-width: 280px;
  color: #626262;
}


.moreButton {
  color: #f7155e;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
}
.moreButton:hover {
  color: #f7155e;
}
.moreButton:hover svg {
  margin-left: .5rem;
}
.moreButton svg {
  transition: ease .5s;
}
.moreButton svg path {
  fill: #f7155e;
}


.anvisa p {
  margin-bottom: 28px;
  color: #f5f9ff
}
.anvisa h2 {
  color: #fff;
}
.bg-green {
  background: var(--primary-bg);
}

.sac-section h2 {
  color: #fff;
}
.sac-section p {
  margin-bottom: 28px;
  color: #f5f9ff;
}

.grid__status {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1.5rem;
}
.status__item {
  text-align: center;
}
.status__item h4 {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
}
.status__item p {
  color: #f5f9ff;
}
.burocra.has-bg::after {
  background-image: url("https://via.placeholder.com/1033x516");
}
.localiza.has-bg::after {
  background-image: url("https://via.placeholder.com/1033x516");
}
.has-bg::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
  right: 0;
  top: 0;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: var(--primary-bg) !important
}

.item__benefits svg {
  min-width: 1rem;
}

.politica h2,
.termos h2 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #4a4a4a;
}
.politica p,
.termos p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #626262;
}


.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}
.float:hover {
  color: #fff;
}

.my-float{
	margin-top:16px;
}

.bar-top {
  background: #222222;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}
.bar-top a {
  color: red;
  animation: colorChanger 5s infinite;
  transition: color 0s ease;
}
@media screen and (max-width: 768px) {
  .bar-top a {
    font-size: 12px;
  }
  .navbar {
    margin: 3rem auto 0;
  }
}

@keyframes colorChanger {
  0% {
    color: red;
  }
  33% {
    color: yellow;
  }
  66% {
    color: white;
  }
  100% {
    color: red;
  }
}